<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0317 3.31283C18.5531 2.791 17.9845 2.37696 17.3584 2.09444C16.7323 1.81193 16.0611 1.6665 15.3832 1.6665C14.7053 1.6665 14.034 1.81193 13.4079 2.09444C12.7818 2.37696 12.2132 2.791 11.7347 3.31283L11.0002 4.12135L10.2657 3.31283C9.7872 2.791 9.21859 2.37696 8.5925 2.09444C7.9664 1.81193 7.29515 1.6665 6.61723 1.6665C5.93931 1.6665 5.26806 1.81193 4.64196 2.09444C4.01587 2.37696 3.44726 2.791 2.96873 3.31283C0.946553 5.51034 0.822552 9.22123 3.36935 12.0407L11.0002 20.3332L18.6311 12.0407C21.1779 9.22123 21.0538 5.51034 19.0317 3.31283Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.0006 4.12109L8.01503 7.3759C7.63757 7.78943 7.42578 8.34828 7.42578 8.93074C7.42578 9.51319 7.63757 10.0721 8.01503 10.4856V10.4856C8.39556 10.8958 8.90983 11.1259 9.44581 11.1259C9.98179 11.1259 10.4961 10.8958 10.8766 10.4856L13.0323 8.19478C13.5681 7.61875 14.2913 7.29565 15.0449 7.29565C15.7986 7.29565 16.5218 7.61875 17.0576 8.19478L19.3468 10.6825M16.7237 14.1135L14.816 12.0404M13.8622 17.2232L11.9545 15.1501"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
